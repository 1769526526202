import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import Web3 from "web3";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Approve_Withdraw, url } from "../../../services/api_function";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
export const ApproveWithdraw = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [fullcsvData, setfullcsvData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const pageSize = 100;
  const token=useSelector((state)=>state.auth.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Approve_Withdraw(
          currentPage,
          { searchQuery: search },
          token
        );
        const { data, totalCount } = response;
        setFilteredData(data);
        const pages = Math.ceil(totalCount / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
        const ModifiedData = data.map((item) => {
          const {
            Name,
            user,
            withdrawAmount,
            wallet_type,
            createdAt,
            payment_method,
            trxnHash,
            timestamp,
          } = item;
          const RequestTime = moment(createdAt).format("DD-MM-YYYY HH:mm");
          const ApproveTime = moment(timestamp).format("DD-MM-YYYY HH:mm");
          return {
            name: Name,
            user: user,
            amount: (withdrawAmount * 0.95).toFixed(2),
            fess: (withdrawAmount * 0.05).toFixed(2),
            total: withdrawAmount.toFixed(2),
            type: wallet_type,
            method: payment_method,
            trxnHash: trxnHash,
            Request: RequestTime,
            ApproveTime: ApproveTime,
          };
        });
        setcsvData(ModifiedData);
        handleDownload();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const columns = [
    { label: "Name", key: "name" },
    { label: "User", key: "user" },
    { label: "Amount", key: "amount" },
    { label: "Fess 5%", key: "fess" },
    { label: "Total", key: "total" },
    { label: "Type", key: "type" },
    { label: "Method", key: "method" },
    { label: "Transaction ID", key: "trxnHash" },
    { label: "Request Time", key: "Request" },
    { label: "Approve Time", key: "ApproveTime" },
  ];

  const handleDownload = async () => {
    try {

      const response = await axios.get(url + "/approved-withdraw-csv", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response?.data?.data;
      if (data) {
        const ModifiedData = data.map((item) => {
          const {
            Name,
            user,
            withdrawAmount,
            wallet_type,
            createdAt,
            payment_method,
            trxnHash,
            timestamp,
          } = item;
          const RequestTime = moment(createdAt).format("DD-MM-YYYY HH:mm");
          const ApproveTime = moment(timestamp).format("DD-MM-YYYY HH:mm");
          return {
            name: Name,
            user: user,
            amount: (withdrawAmount * 0.95).toFixed(2),
            fess: (withdrawAmount * 0.05).toFixed(2),
            total: withdrawAmount.toFixed(2),
            type: wallet_type,
            method: payment_method,
            trxnHash: trxnHash,
            Request: RequestTime,
            ApproveTime: ApproveTime,
          };
        });
        setfullcsvData(ModifiedData);
      } else {
        console.error("No data found.");
        setIsDataLoaded(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsDataLoaded(false);
    }
  };

  const copyUser = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.info("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Approved Withdraw Referral
              </Card.Title>
              <CSVLink
                data={csvData}
                headers={columns}
                filename={"withdrawReferral.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                Page CSV
              </CSVLink>
              <CSVLink
                data={fullcsvData}
                headers={columns}
                filename={"withdrawReferralfull.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                Full CSV
              </CSVLink>
            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>No</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>User</strong>
                    </th>
                    <th>
                      <strong>Amount</strong>
                    </th>
                    <th>
                      <strong>Fess(5%)</strong>
                    </th>
                    <th>
                      <strong>Total</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>Method</strong>
                    </th>
                    <th>
                      <strong>Transaction ID</strong>
                    </th>
                    <th>
                      <strong>Approve</strong>
                    </th>
                    <th>
                      <strong>Request</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((Data, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{Data?.Name}</td>
                        <td>{Data?.user}
                        <i
                          className="far fa-copy"
                          onClick={() => copyUser(Data?.user)}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        ></i>
                        </td>
                        <td>{(Data?.withdrawAmount * 0.95).toFixed(2)}</td>
                        <td>{(Data?.withdrawAmount * 0.05).toFixed(2)}</td>
                        <td>{(Data?.withdrawAmount).toFixed(2)}</td>
                        <td>{Data?.wallet_type}</td>
                        <td>{Data?.payment_method}</td>
                        <td>
                          <a
                            href={`https://bscscan.com/tx/${Data?.trxnHash}`}
                            className="text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {Data?.trxnHash
                              ? `${Data?.trxnHash.slice(
                                  0,
                                  7
                                )}...${Data?.trxnHash.slice(-5)}`
                              : ""}
                          </a>
                        </td>
                        <td>
                          {formatTimestamp(Data?.timestamp ?? Data?.createdAt)}
                        </td>
                        <td>
                          {formatTimestamp(Data?.createdAt ?? Data?.timestamp)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ApproveWithdraw;
