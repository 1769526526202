import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { DownloadExcel } from "react-excel-export";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import * as XLSX from "xlsx";

import { Row, Col, Card, Table } from "react-bootstrap";
import { Wyz_User } from "../../../../services/api_function";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import moment from "moment";
export const WyzUSer = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pageSize = 100;

  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Wyz_User(
          currentPage,
          { searchQuery: search },
          token
        );
        setApiData(result?.data);
        setFilteredData(result?.data);
        const total = result?.totalUsers;
        const pages = Math.ceil(total / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
        if (result.status == 404) {
          navigate("/login");
          localStorage.removeItem("userDetails");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };
  const copyUser = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.info("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                All Users
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{
                background: "black",
                border: "1px solid white",
                borderRadius: "3px",
              }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "0.5px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO.</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>Phone</strong>
                    </th>
                    <th>
                      <strong> UserID</strong>
                    </th>
                    <th>
                      <strong>User wallet</strong>
                    </th>
                    <th>
                      <strong>referrerId</strong>
                    </th>

                    <th>
                      <strong>Token</strong>
                    </th>
                    <th>
                      <strong>WYZ</strong>
                    </th>
                    <th>
                      <strong>stUSDT</strong>
                    </th>
                    <th>
                      <strong>Direct/Team Business Live</strong>
                    </th>
                    <th>
                      <strong>Direct/Team Business Finish</strong>
                    </th>

                    <th>
                      <strong>Date&Time</strong>
                    </th>
                    <th>
                      <strong>Team</strong>
                    </th>
                    <th>
                      <strong>Commission </strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((user, index) => (
                    <tr>
                      <td>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>{user?.name}</td>
                      <td>{user?.phone}</td>
                      <td>{user?.userId}</td>
                      <td>
                        {" "}
                        <span className="smaller-font">
                          {user?.user?.slice(0, 4) +
                            "..." +
                            user?.user.slice(-12)}
                        </span>
                        <i
                          className="far fa-copy"
                          onClick={() => copyUser(user?.user)}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        ></i>
                      </td>
                      <td>
                        {user?.referrerId == 0
                          ? user?.referalId
                          : user?.referrerId}
                      </td>
                      <td>{user?.token}</td>
                      <td>{Number(user?.wyz_amount ?? 0).toFixed(2)}</td>
                      <td>{Number(user?.amount ?? 0).toFixed(2)}</td>
                      <td className="text-center">
                        {Number(user?.directbizliveWYZ ?? 0).toFixed(2)} /{" "}
                        {Number(user?.staketeambusinessWYZ ?? 0).toFixed(2)}
                                   
                      </td>
                      <td className="text-center">
                        {user?.directplusteambiz > 0
                          ? (
                              user?.directplusteambiz - user?.staketeambusiness
                            ).toFixed(2)
                          : "0.00"}
                        /{Number(user?.staketeambusiness ?? 0).toFixed(2)}
                                   
                      </td>

                      <td>
                        {user?.createdAt
                          ? moment(user.createdAt).format("DD/MM/YYYY HH:mm")
                          : "N/A"}
                      </td>

                      <td>
                        <div className="text-center">
                          <Link
                            to={`/team-wyz?user=${encodeURIComponent(
                              user.user
                            )}`}
                            className="btn btn-primary light shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          <Link
                            to={`/comisson-wyz?user=${encodeURIComponent(
                              user.user
                            )}`}
                            className="btn btn-primary light shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="bg-black text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default WyzUSer;
