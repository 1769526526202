import { Fragment, useEffect, useState } from "react";

import { Row, Col, Card, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Protocal_data, url } from "../../../services/api_function";
import { useSelector } from "react-redux";
import axios from "axios";
import { CSVLink } from "react-csv";

const ProtocalData = () => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const ratio = new URLSearchParams(location.search).get("ratio");
  const [apidata, setApidata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [fullcsvData, setfullcsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const pageSize = 100;
  const token2 = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      if (token && ratio) {
        try {
          const data = await Protocal_data(currentPage, ratio, token, token2);
          setApidata(data?.protocol);
          const pages = Math.ceil(data.totalUser / pageSize);
          setTotalPages(pages > 0 ? pages : 1);
          handleDownload();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [currentPage]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} `;
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const columns = [
    { label: "Name", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "UserId", key: "userId" },
    { label: "User", key: "user" },
    { label: "Referr", key: "referrerId" },
    { label: "Token", key: "token" },
    { label: "Ratio", key: "ratio" },
    { label: "Amount", key: "topup_amount" },
    { label: "Withdraw", key: "totalWithdraw" },
    { label: "Date&Time", key: "Timestamp" },
    { label: "MonthPaid", key: "monthPaid" },
    { label: "MonthLeft", key: "monthsLeft" },
    { label: "2X", key: "twoX" },
    { label: "Month Roi", key: "roi" },
    { label: "MB", key: "mb" },
    { label: "DayComplete", key: "daysCompleted" },
    { label: "DaysLeft", key: "daysLeft" },
    { label: "Balance", key: "totalBalnce" },
    { label: "DailyRoi", key: "dailyROI" },
  ];

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${url}/protocol-data-csv?ratio=${ratio}&token=${token}`,
        {
          headers: {
            Authorization: `Bearer ${token2}`,
          },
        }
      );

      if (response) {
        setfullcsvData(response?.data?.protocol);
        setLoading(false);
      } else {
        console.error("No data found.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {/* <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div> */}
          <label class="form-label" for="form1"></label>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <i
                class="fas fa-circle-left "
                style={{ fontSize: "2rem" }}
                onClick={() => navigate(-1)}
              ></i>
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Protocol User
              </Card.Title>
              {/* <CSVLink
                data={fullcsvData}
                headers={columns}
                filename={`${token},ratio${ratio}.csv`}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                CSV
              </CSVLink> */}

              {fullcsvData.length > 0 && !loading && (
                <CSVLink
                  data={fullcsvData}
                  headers={columns}
                  filename={`${token},ratio${ratio}.csv`}
                  style={{
                    background: "white",
                    color: "black",
                    padding: "10px 20px",
                    textDecoration: "none",
                    borderRadius: "5px",
                    border: "1px solid black",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                >
                  CSV File
                </CSVLink>
              )}
            </Card.Header>
            <Card.Body
              style={{
                background: "black",
                border: "1px solid white",
                borderRadius: "3px",
              }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "0.5px solid white",
                }}
              >
                {/* <button onClick={() => exportToExcel(data, 'exported-data')}>Export to Excel</button> */}
                <thead>
                  <tr>
                    <th>
                      <strong>NO.</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>Phone</strong>
                    </th>
                    <th>
                      <strong> UserID</strong>
                    </th>
                    <th>
                      {/* <thead>
                        <input
                          type="text"
                          class="form-control"
                          // style={{ width: "70%" }}
                          placeholder="Search here..."
                          onChange={handleSearch}
                        />
                      </thead> */}
                      <strong>User wallet</strong>
                    </th>
                    <th>
                      <strong>referrerId</strong>
                    </th>
                    <th>
                      <strong>Amount</strong>
                    </th>
                    <th>
                      <strong>Total Withdraw</strong>
                    </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                    <th>Month Paid</th>
                    <th>Month Left</th>

                    <th>2X</th>
                    <th>Month Roi</th>
                    <th>MB</th>
                  </tr>
                </thead>
                {/* <tbody>
                  {apidata.map((user, index) => (
                    <tr>
                      <td>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.phone}</td>
                      <td>{user.userId}</td>
                      <td>
                        {" "}
                        <span className="smaller-font">
                          {user?.user?.slice(0, 4) +
                            "..." +
                            user?.user.slice(-12)}
                        </span>
                      </td>
                      <td>{user.referrerId}</td>
                      <td>{(user?.topup_amount).toFixed(2)}</td>
                      <td className="text-center">
                        {(user?.totalWithdraw).toFixed(2)}
                      </td>

                      <td>{formatTimestamp(user.createdAt)}</td>
                      <td></td>
                      <td>{(user?.topup_amount * 2).toFixed(2)}</td>
                     
                    </tr>
                  ))}
                </tbody> */}

                <tbody>
                  {apidata.map((user, index) => {
                    let planDuration = 0;
                    let monthlyROI = 0;
                    if (user?.token == "WYZ-stUSDT" && user?.ratio == 10) {
                      planDuration = 12;
                      monthlyROI = 16.67;
                    } else if (
                      user?.token == "WYZ-stUSDT" &&
                      user?.ratio == 20
                    ) {
                      planDuration = 24;
                      monthlyROI = 8.33;
                    } else if (
                      user?.token == "WYZ-stUSDT" &&
                      user?.ratio == 30
                    ) {
                      planDuration = 36;
                      monthlyROI = 5.56;
                    } else if (
                      user?.token == "WYZ-stUSDT" &&
                      user?.ratio == 40
                    ) {
                      planDuration = 48;
                      monthlyROI = 4.17;
                    } else if (
                      user?.token == "WYZ-stUSDT" &&
                      user?.ratio == 50
                    ) {
                      planDuration = 60;
                      monthlyROI = 3.33;
                    } else if (
                      user?.token == "sUSDT-stUSDT" &&
                      user?.ratio == 15
                    ) {
                      planDuration = 24;
                      monthlyROI = 8.33;
                    } else if (
                      user?.token == "sUSDT-stUSDT" &&
                      user?.ratio == 20
                    ) {
                      planDuration = 18;
                      monthlyROI = 11.11;
                    } else if (
                      user?.token == "sUSDT-stUSDT" &&
                      user?.ratio == 25
                    ) {
                      planDuration = 30;
                      monthlyROI = 6.67;
                    }

                    const topupROI = user?.topup_amount
                      ? (user.topup_amount * (monthlyROI / 100)).toFixed(2)
                      : 0;
                    const monthPaid =
                      user?.totalWithdraw > 0 && topupROI > 0
                        ? (user.totalWithdraw / topupROI).toFixed(0)
                        : 0;

                    const monthsLeft = planDuration - monthPaid;

                    return (
                      <tr key={index}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.phone}</td>
                        <td>{user.userId}</td>
                        <td>
                          <span className="smaller-font">
                            {user?.user?.slice(0, 4) +
                              "..." +
                              user?.user.slice(-12)}
                          </span>
                        </td>
                        <td>{user.referrerId}</td>
                        <td>{(user?.topup_amount).toFixed(2)}</td>
                        <td className="text-center">
                          {(user?.totalWithdraw).toFixed(2)}
                        </td>
                        <td>{formatTimestamp(user.createdAt)}</td>
                        <td className="text-center">{monthPaid} </td>
                        <td className="text-center">{monthsLeft} </td>

                        <td>{(user?.topup_amount * 2).toFixed(2)}</td>
                        <td className="text-center">{topupROI}</td>
                        <td className="text-center">
                          {(topupROI - user?.totalWithdraw).toFixed(2)}
                        </td>
                        {/* <td className="text-center">{topupWithdrawRatio}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <div className="d-flex justify-content-between">
                <span>
                  <strong></strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage == 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="bg-black text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProtocalData;
