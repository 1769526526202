import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { Protocal_Wyz } from "../../../../services/api_function";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const ProtocalWyz = () => {
  const [api, setApi] = useState([]);
  const token=useSelector((state)=>state.auth.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Protocal_Wyz(token);
        setApi(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Protocol
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong> Protocol</strong>
                    </th>
                    <th>
                      <strong>Ratio</strong>
                    </th>

                    <th>
                      <strong>Tenure</strong>
                    </th>
                    <th>
                      <strong>WYZ</strong>
                    </th>
                    <th>
                      <strong>stUSDT</strong>
                    </th>
                
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> WYZ </td>
                    <td> 100</td>
                    <td>12 Month</td>
                    <td>{Number(api?.totalWyzdeposite )?.toFixed(2)}</td>
                    <td>{(api?.totalWyzUsdtdeposite)?.toFixed(2)}</td>
                    <td>
                      <Link
                        to="/wyz-protocol-user?token=WYZ"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>stUSDT</td>
                    <td>100</td>
                    <td>12 Month</td>
                    <td>{Number(api?.totalUsdtWyz)?.toFixed(2)}</td>
                    <td>{(api?.totalUsdtusdt )?.toFixed(2)}</td>
                    <td>
                      <Link
                        to="/wyz-protocol-user?token=AllstUSDT"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                   
                  </tr>
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProtocalWyz;
