import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Commissiondata } from "../../../services/api_function";
import { useSelector } from "react-redux";

const CommissionData = (props) => {
  const location = useLocation();
  const phoneNumberFromUrl = new URLSearchParams(location.search).get("user");
  const [user, setUser] = useState(phoneNumberFromUrl);
  const [userData, setUserData] = useState([]);

  const isInitialRender = useRef(true);
  const User = useMemo(() => user, [user]);
  const token = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    Commissiondata(User, token)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching team data:", error);
      });
  }, [User]);

  // console.log("UserData:", userData);
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <i
                class="fas fa-circle-left "
                style={{ fontSize: "2rem" }}
                onClick={() => navigate(-1)}
              ></i>
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Commission
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>Rank Bonus</strong>
                    </th>
                    <th>
                      <strong>Recuring Bonus</strong>
                    </th>
                    <th>
                      <strong>Recuring Total</strong>
                    </th>
                    <th>
                      <strong>Level Bonus</strong>
                    </th>
                    <th>
                      <strong>Direct Bonuss</strong>
                    </th>
                    <th>
                      <strong>Pool Bonus</strong>
                    </th>
                    <th>
                      <strong>Total Withdraw</strong>
                    </th>
                    <th>
                      <strong>TopUp Amount</strong>
                    </th>
                    <th>
                      <strong>Total Income</strong>
                    </th>
                    <th>
                      <strong>ROI</strong>
                    </th>
                    <th>
                      <strong>ROI Total</strong>
                    </th>
                    <th>
                      <strong>Team Business</strong>
                    </th>
                    <th>
                      <strong>Seventy</strong>
                    </th>
                    <th>
                      <strong>Thirty</strong>
                    </th>
                    <th>
                      <strong>2X Complete</strong>
                    </th>
                    <th>
                      <strong>WYZ Balance</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">
                      {(userData?.users?.rankbonus ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.wallet_recurr ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.recurrIncome ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.levelIncome ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.referalIncome ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.poolbonus ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.totalWithdraw ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.topup_amount ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.totalIncome ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.users?.wallet_roi ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(
                        (userData?.users?.wallet_roi ?? 0) +
                        (userData?.users?.roi_withdraw ?? 0)
                      ).toFixed(2)}
                    </td>

                    <td className="text-center">
                      {(userData?.teamBussines ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {(userData?.seventyPercentOfHighest ?? 0).toFixed(2)}
                    </td>

                    <td className="text-center">
                      {(userData?.thirtyPercentOfRemainingSum ?? 0).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {userData?.users?.complete2X == true
                        ? "Complete"
                        : "Pending"}
                    </td>

                    <td className="text-center">
                      {(userData?.users?.wyz_wallet_income ?? 0).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CommissionData;
